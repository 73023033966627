(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(acdlreq,module,exports){
(function (global){
/**
 * @license
 * Lodash (Custom Build) lodash.com/license | Underscore.js 1.8.3 underscorejs.org/LICENSE
 * Build: `lodash exports="node" include="cloneDeep,cloneDeepWith,find,get,has,includes,isEmpty,isEqual,isNull,isPlainObject,isObject,merge,mergeWith,omit,reject" -p -o custom-lodash.js`
 */
;(function(){function t(t,e,n){switch(n.length){case 0:return t.call(e);case 1:return t.call(e,n[0]);case 2:return t.call(e,n[0],n[1]);case 3:return t.call(e,n[0],n[1],n[2])}return t.apply(e,n)}function e(t,e){for(var n=-1,r=null==t?0:t.length;++n<r&&false!==e(t[n],n,t););}function n(t,e){for(var n=-1,r=null==t?0:t.length,o=0,u=[];++n<r;){var c=t[n];e(c,n,t)&&(u[o++]=c)}return u}function r(t,e){for(var n=-1,r=null==t?0:t.length,o=Array(r);++n<r;)o[n]=e(t[n],n,t);return o}function o(t,e){for(var n=-1,r=e.length,o=t.length;++n<r;)t[o+n]=e[n];
return t}function u(t,e){for(var n=-1,r=null==t?0:t.length;++n<r;)if(e(t[n],n,t))return true;return false}function c(t,e,n){var r=t.length;for(n+=-1;++n<r;)if(e(t[n],n,t))return n;return-1}function i(t){return t!==t}function a(t){return function(e){return null==e?re:e[t]}}function f(t){return function(e){return t(e)}}function l(t,e){return r(e,function(e){return t[e]})}function s(t){var e=-1,n=Array(t.size);return t.forEach(function(t,r){n[++e]=[r,t]}),n}function b(t){var e=Object;return function(n){return t(e(n));
}}function h(t){var e=-1,n=Array(t.size);return t.forEach(function(t){n[++e]=t}),n}function p(){}function y(t){var e=-1,n=null==t?0:t.length;for(this.clear();++e<n;){var r=t[e];this.set(r[0],r[1])}}function j(t){var e=-1,n=null==t?0:t.length;for(this.clear();++e<n;){var r=t[e];this.set(r[0],r[1])}}function _(t){var e=-1,n=null==t?0:t.length;for(this.clear();++e<n;){var r=t[e];this.set(r[0],r[1])}}function g(t){var e=-1,n=null==t?0:t.length;for(this.__data__=new _;++e<n;)this.add(t[e])}function v(t){
this.size=(this.__data__=new j(t)).size}function d(t,e){var n=xn(t),r=!n&&zn(t),o=!n&&!r&&En(t),u=!n&&!r&&!o&&Mn(t);if(n=n||r||o||u){for(var r=t.length,c=String,i=-1,a=Array(r);++i<r;)a[i]=c(i);r=a}else r=[];var f,c=r.length;for(f in t)!e&&!Ue.call(t,f)||n&&("length"==f||o&&("offset"==f||"parent"==f)||u&&("buffer"==f||"byteLength"==f||"byteOffset"==f)||vt(f,c))||r.push(f);return r}function A(t,e,n){(n===re||Ft(t[e],n))&&(n!==re||e in t)||k(t,e,n)}function w(t,e,n){var r=t[e];Ue.call(t,e)&&Ft(r,n)&&(n!==re||e in t)||k(t,e,n);
}function m(t,e){for(var n=t.length;n--;)if(Ft(t[n][0],e))return n;return-1}function O(t,e){return t&&et(e,Jt(e),t)}function S(t,e){return t&&et(e,Kt(e),t)}function k(t,e,n){"__proto__"==e&&Qe?Qe(t,e,{configurable:true,enumerable:true,value:n,writable:true}):t[e]=n}function z(t,n,r,o,u,c){var i,a=1&n,f=2&n,l=4&n;if(r&&(i=u?r(t,o,u,c):r(t)),i!==re)return i;if(!Bt(t))return t;if(o=xn(t)){if(i=yt(t),!a)return tt(t,i)}else{var s=mn(t),b="[object Function]"==s||"[object GeneratorFunction]"==s;if(En(t))return X(t,a);
if("[object Object]"==s||"[object Arguments]"==s||b&&!u){if(i=f||b?{}:jt(t),!a)return f?rt(t,S(i,t)):nt(t,O(i,t))}else{if(!ge[s])return u?t:{};i=_t(t,s,a)}}if(c||(c=new v),u=c.get(t))return u;if(c.set(t,i),Fn(t))return t.forEach(function(e){i.add(z(e,n,r,e,t,c))}),i;if(In(t))return t.forEach(function(e,o){i.set(o,z(e,n,r,o,t,c))}),i;var f=l?f?ft:at:f?Kt:Jt,h=o?re:f(t);return e(h||t,function(e,o){h&&(o=e,e=t[o]),w(i,o,z(e,n,r,o,t,c))}),i}function x(t,e){var n=[];return gn(t,function(t,r,o){e(t,r,o)&&n.push(t);
}),n}function E(t,e,n,r,u){var c=-1,i=t.length;for(n||(n=gt),u||(u=[]);++c<i;){var a=t[c];0<e&&n(a)?1<e?E(a,e-1,n,r,u):o(u,a):r||(u[u.length]=a)}return u}function I(t,e){e=Q(e,t);for(var n=0,r=e.length;null!=t&&n<r;)t=t[Ot(e[n++])];return n&&n==r?t:re}function F(t,e,n){return e=e(t),xn(t)?e:o(e,n(t))}function M(t){if(null==t)t=t===re?"[object Undefined]":"[object Null]";else if(Ke&&Ke in Object(t)){var e=Ue.call(t,Ke),n=t[Ke];try{t[Ke]=re;var r=true}catch(t){}var o=Pe.call(t);r&&(e?t[Ke]=n:delete t[Ke]),
t=o}else t=Pe.call(t);return t}function $(t,e){return null!=t&&Ue.call(t,e)}function D(t,e){return null!=t&&e in Object(t)}function U(t){return Pt(t)&&"[object Arguments]"==M(t)}function B(t,e,n,r,o){if(t===e)e=true;else if(null==t||null==e||!Pt(t)&&!Pt(e))e=t!==t&&e!==e;else t:{var u=xn(t),c=xn(e),i=u?"[object Array]":mn(t),a=c?"[object Array]":mn(e),i="[object Arguments]"==i?"[object Object]":i,a="[object Arguments]"==a?"[object Object]":a,f="[object Object]"==i,c="[object Object]"==a;if((a=i==a)&&En(t)){
if(!En(e)){e=false;break t}u=true,f=false}if(a&&!f)o||(o=new v),e=u||Mn(t)?ct(t,e,n,r,B,o):it(t,e,i,n,r,B,o);else{if(!(1&n)&&(u=f&&Ue.call(t,"__wrapped__"),i=c&&Ue.call(e,"__wrapped__"),u||i)){t=u?t.value():t,e=i?e.value():e,o||(o=new v),e=B(t,e,n,r,o);break t}if(a)e:if(o||(o=new v),u=1&n,i=at(t),c=i.length,a=at(e).length,c==a||u){for(f=c;f--;){var l=i[f];if(!(u?l in e:Ue.call(e,l))){e=false;break e}}if((a=o.get(t))&&o.get(e))e=a==e;else{a=true,o.set(t,e),o.set(e,t);for(var s=u;++f<c;){var l=i[f],b=t[l],h=e[l];
if(r)var p=u?r(h,b,l,e,t,o):r(b,h,l,t,e,o);if(p===re?b!==h&&!B(b,h,n,r,o):!p){a=false;break}s||(s="constructor"==l)}a&&!s&&(n=t.constructor,r=e.constructor,n!=r&&"constructor"in t&&"constructor"in e&&!(typeof n=="function"&&n instanceof n&&typeof r=="function"&&r instanceof r)&&(a=false)),o.delete(t),o.delete(e),e=a}}else e=false;else e=false}}return e}function P(t){return Pt(t)&&"[object Map]"==mn(t)}function L(t,e){var n=e.length,r=n;if(null==t)return!r;for(t=Object(t);n--;){var o=e[n];if(o[2]?o[1]!==t[o[0]]:!(o[0]in t))return false;
}for(;++n<r;){var o=e[n],u=o[0],c=t[u],i=o[1];if(o[2]){if(c===re&&!(u in t))return false}else if(o=new v,void 0===re?!B(i,c,3,void 0,o):1)return false}return true}function N(t){return Pt(t)&&"[object Set]"==mn(t)}function C(t){return Pt(t)&&Ut(t.length)&&!!_e[M(t)]}function T(t){return typeof t=="function"?t:null==t?Yt:typeof t=="object"?xn(t)?W(t[0],t[1]):R(t):te(t)}function V(t){if(!At(t))return Ze(t);var e,n=[];for(e in Object(t))Ue.call(t,e)&&"constructor"!=e&&n.push(e);return n}function R(t){var e=bt(t);
return 1==e.length&&e[0][2]?wt(e[0][0],e[0][1]):function(n){return n===t||L(n,e)}}function W(t,e){return dt(t)&&e===e&&!Bt(e)?wt(Ot(t),e):function(n){var r=Gt(n,t);return r===re&&r===e?Ht(n,t):B(e,r,3)}}function q(t,e,n,r,o){t!==e&&vn(e,function(u,c){if(Bt(u)){o||(o=new v);var i=o,a="__proto__"==c?re:t[c],f="__proto__"==c?re:e[c],l=i.get(f);if(l)A(t,c,l);else{var l=r?r(a,f,c+"",t,e,i):re,s=l===re;if(s){var b=xn(f),h=!b&&En(f),p=!b&&!h&&Mn(f),l=f;b||h||p?xn(a)?l=a:$t(a)?l=tt(a):h?(s=false,l=X(f,true)):p?(s=false,
l=Z(f,true)):l=[]:Lt(f)||zn(f)?(l=a,zn(a)?l=Wt(a):(!Bt(a)||n&&Dt(a))&&(l=jt(f))):s=false}s&&(i.set(f,l),q(l,f,n,r,i),i.delete(f)),A(t,c,l)}}else i=r?r("__proto__"==c?re:t[c],u,c+"",t,e,o):re,i===re&&(i=u),A(t,c,i)},Kt)}function G(t){return function(e){return I(e,t)}}function H(t){return On(mt(t,void 0,Yt),t+"")}function J(t){if(typeof t=="string")return t;if(xn(t))return r(t,J)+"";if(Ct(t))return jn?jn.call(t):"";var e=t+"";return"0"==e&&1/t==-oe?"-0":e}function K(t,e){e=Q(e,t);var n;if(2>e.length)n=t;else{
n=e;var r=0,o=-1,u=-1,c=n.length;for(0>r&&(r=-r>c?0:c+r),o=o>c?c:o,0>o&&(o+=c),c=r>o?0:o-r>>>0,r>>>=0,o=Array(c);++u<c;)o[u]=n[u+r];n=I(t,o)}t=n,null==t||delete t[Ot(xt(e))]}function Q(t,e){return xn(t)?t:dt(t,e)?[t]:Sn(qt(t))}function X(t,e){if(e)return t.slice();var n=t.length,n=Re?Re(n):new t.constructor(n);return t.copy(n),n}function Y(t){var e=new t.constructor(t.byteLength);return new Ve(e).set(new Ve(t)),e}function Z(t,e){return new t.constructor(e?Y(t.buffer):t.buffer,t.byteOffset,t.length);
}function tt(t,e){var n=-1,r=t.length;for(e||(e=Array(r));++n<r;)e[n]=t[n];return e}function et(t,e,n){var r=!n;n||(n={});for(var o=-1,u=e.length;++o<u;){var c=e[o],i=re;i===re&&(i=t[c]),r?k(n,c,i):w(n,c,i)}return n}function nt(t,e){return et(t,An(t),e)}function rt(t,e){return et(t,wn(t),e)}function ot(t){return H(function(e,n){var r,o=-1,u=n.length,c=1<u?n[u-1]:re,i=2<u?n[2]:re,c=3<t.length&&typeof c=="function"?(u--,c):re;if(r=i){r=n[0];var a=n[1];if(Bt(i)){var f=typeof a;r=!!("number"==f?Mt(i)&&vt(a,i.length):"string"==f&&a in i)&&Ft(i[a],r);
}else r=false}for(r&&(c=3>u?re:c,u=1),e=Object(e);++o<u;)(i=n[o])&&t(e,i,o,c);return e})}function ut(t){return Lt(t)?re:t}function ct(t,e,n,r,o,c){var i=1&n,a=t.length,f=e.length;if(a!=f&&!(i&&f>a))return false;if((f=c.get(t))&&c.get(e))return f==e;var f=-1,l=true,s=2&n?new g:re;for(c.set(t,e),c.set(e,t);++f<a;){var b=t[f],h=e[f];if(r)var p=i?r(h,b,f,e,t,c):r(b,h,f,t,e,c);if(p!==re){if(p)continue;l=false;break}if(s){if(!u(e,function(t,e){if(!s.has(e)&&(b===t||o(b,t,n,r,c)))return s.push(e)})){l=false;break}}else if(b!==h&&!o(b,h,n,r,c)){
l=false;break}}return c.delete(t),c.delete(e),l}function it(t,e,n,r,o,u,c){switch(n){case"[object DataView]":if(t.byteLength!=e.byteLength||t.byteOffset!=e.byteOffset)break;t=t.buffer,e=e.buffer;case"[object ArrayBuffer]":if(t.byteLength!=e.byteLength||!u(new Ve(t),new Ve(e)))break;return true;case"[object Boolean]":case"[object Date]":case"[object Number]":return Ft(+t,+e);case"[object Error]":return t.name==e.name&&t.message==e.message;case"[object RegExp]":case"[object String]":return t==e+"";case"[object Map]":
var i=s;case"[object Set]":if(i||(i=h),t.size!=e.size&&!(1&r))break;return(n=c.get(t))?n==e:(r|=2,c.set(t,e),e=ct(i(t),i(e),r,o,u,c),c.delete(t),e);case"[object Symbol]":if(yn)return yn.call(t)==yn.call(e)}return false}function at(t){return F(t,Jt,An)}function ft(t){return F(t,Kt,wn)}function lt(){var t=p.iteratee||Zt,t=t===Zt?T:t;return arguments.length?t(arguments[0],arguments[1]):t}function st(t,e){var n=t.__data__,r=typeof e;return("string"==r||"number"==r||"symbol"==r||"boolean"==r?"__proto__"!==e:null===e)?n[typeof e=="string"?"string":"hash"]:n.map;
}function bt(t){for(var e=Jt(t),n=e.length;n--;){var r=e[n],o=t[r];e[n]=[r,o,o===o&&!Bt(o)]}return e}function ht(t,e){var n=null==t?re:t[e];return(!Bt(n)||Be&&Be in n?0:(Dt(n)?Ne:pe).test(St(n)))?n:re}function pt(t,e,n){e=Q(e,t);for(var r=-1,o=e.length,u=false;++r<o;){var c=Ot(e[r]);if(!(u=null!=t&&n(t,c)))break;t=t[c]}return u||++r!=o?u:(o=null==t?0:t.length,!!o&&Ut(o)&&vt(c,o)&&(xn(t)||zn(t)))}function yt(t){var e=t.length,n=new t.constructor(e);return e&&"string"==typeof t[0]&&Ue.call(t,"index")&&(n.index=t.index,
n.input=t.input),n}function jt(t){return typeof t.constructor!="function"||At(t)?{}:_n(We(t))}function _t(t,e,n){var r=t.constructor;switch(e){case"[object ArrayBuffer]":return Y(t);case"[object Boolean]":case"[object Date]":return new r(+t);case"[object DataView]":return e=n?Y(t.buffer):t.buffer,new t.constructor(e,t.byteOffset,t.byteLength);case"[object Float32Array]":case"[object Float64Array]":case"[object Int8Array]":case"[object Int16Array]":case"[object Int32Array]":case"[object Uint8Array]":
case"[object Uint8ClampedArray]":case"[object Uint16Array]":case"[object Uint32Array]":return Z(t,n);case"[object Map]":return new r;case"[object Number]":case"[object String]":return new r(t);case"[object RegExp]":return e=new t.constructor(t.source,se.exec(t)),e.lastIndex=t.lastIndex,e;case"[object Set]":return new r;case"[object Symbol]":return yn?Object(yn.call(t)):{}}}function gt(t){return xn(t)||zn(t)||!!(Je&&t&&t[Je])}function vt(t,e){var n=typeof t;return e=null==e?9007199254740991:e,!!e&&("number"==n||"symbol"!=n&&je.test(t))&&-1<t&&0==t%1&&t<e;
}function dt(t,e){if(xn(t))return false;var n=typeof t;return!("number"!=n&&"symbol"!=n&&"boolean"!=n&&null!=t&&!Ct(t))||(ie.test(t)||!ce.test(t)||null!=e&&t in Object(e))}function At(t){var e=t&&t.constructor;return t===(typeof e=="function"&&e.prototype||Me)}function wt(t,e){return function(n){return null!=n&&(n[t]===e&&(e!==re||t in Object(n)))}}function mt(e,n,r){return n=tn(n===re?e.length-1:n,0),function(){for(var o=arguments,u=-1,c=tn(o.length-n,0),i=Array(c);++u<c;)i[u]=o[n+u];for(u=-1,c=Array(n+1);++u<n;)c[u]=o[u];
return c[n]=r(i),t(e,this,c)}}function Ot(t){if(typeof t=="string"||Ct(t))return t;var e=t+"";return"0"==e&&1/t==-oe?"-0":e}function St(t){if(null!=t){try{return De.call(t)}catch(t){}return t+""}return""}function kt(t,e,n){var r=null==t?0:t.length;return r?(n=null==n?0:Vt(n),0>n&&(n=tn(r+n,0)),c(t,lt(e,3),n)):-1}function zt(t){return(null==t?0:t.length)?E(t,1):[]}function xt(t){var e=null==t?0:t.length;return e?t[e-1]:re}function Et(t,e){function n(){var r=arguments,o=e?e.apply(this,r):r[0],u=n.cache;
return u.has(o)?u.get(o):(r=t.apply(this,r),n.cache=u.set(o,r)||u,r)}if(typeof t!="function"||null!=e&&typeof e!="function")throw new TypeError("Expected a function");return n.cache=new(Et.Cache||_),n}function It(t){if(typeof t!="function")throw new TypeError("Expected a function");return function(){var e=arguments;switch(e.length){case 0:return!t.call(this);case 1:return!t.call(this,e[0]);case 2:return!t.call(this,e[0],e[1]);case 3:return!t.call(this,e[0],e[1],e[2])}return!t.apply(this,e)}}function Ft(t,e){
return t===e||t!==t&&e!==e}function Mt(t){return null!=t&&Ut(t.length)&&!Dt(t)}function $t(t){return Pt(t)&&Mt(t)}function Dt(t){return!!Bt(t)&&(t=M(t),"[object Function]"==t||"[object GeneratorFunction]"==t||"[object AsyncFunction]"==t||"[object Proxy]"==t)}function Ut(t){return typeof t=="number"&&-1<t&&0==t%1&&9007199254740991>=t}function Bt(t){var e=typeof t;return null!=t&&("object"==e||"function"==e)}function Pt(t){return null!=t&&typeof t=="object"}function Lt(t){return!(!Pt(t)||"[object Object]"!=M(t))&&(t=We(t),
null===t||(t=Ue.call(t,"constructor")&&t.constructor,typeof t=="function"&&t instanceof t&&De.call(t)==Le))}function Nt(t){return typeof t=="string"||!xn(t)&&Pt(t)&&"[object String]"==M(t)}function Ct(t){return typeof t=="symbol"||Pt(t)&&"[object Symbol]"==M(t)}function Tt(t){return t?(t=Rt(t),t===oe||t===-oe?1.7976931348623157e308*(0>t?-1:1):t===t?t:0):0===t?t:0}function Vt(t){t=Tt(t);var e=t%1;return t===t?e?t-e:t:0}function Rt(t){if(typeof t=="number")return t;if(Ct(t))return ue;if(Bt(t)&&(t=typeof t.valueOf=="function"?t.valueOf():t,
t=Bt(t)?t+"":t),typeof t!="string")return 0===t?t:+t;t=t.replace(fe,"");var e=he.test(t);return e||ye.test(t)?de(t.slice(2),e?2:8):be.test(t)?ue:+t}function Wt(t){return et(t,Kt(t))}function qt(t){return null==t?"":J(t)}function Gt(t,e,n){return t=null==t?re:I(t,e),t===re?n:t}function Ht(t,e){return null!=t&&pt(t,e,D)}function Jt(t){return Mt(t)?d(t):V(t)}function Kt(t){if(Mt(t))t=d(t,true);else if(Bt(t)){var e,n=At(t),r=[];for(e in t)("constructor"!=e||!n&&Ue.call(t,e))&&r.push(e);t=r}else{if(e=[],
null!=t)for(n in Object(t))e.push(n);t=e}return t}function Qt(t){return null==t?[]:l(t,Jt(t))}function Xt(t){return function(){return t}}function Yt(t){return t}function Zt(t){return T(typeof t=="function"?t:z(t,1))}function te(t){return dt(t)?a(Ot(t)):G(t)}function ee(){return[]}function ne(){return false}var re,oe=1/0,ue=NaN,ce=/\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/,ie=/^\w*$/,ae=/[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g,fe=/^\s+|\s+$/g,le=/\\(\\)?/g,se=/\w*$/,be=/^[-+]0x[0-9a-f]+$/i,he=/^0b[01]+$/i,pe=/^\[object .+?Constructor\]$/,ye=/^0o[0-7]+$/i,je=/^(?:0|[1-9]\d*)$/,_e={};
_e["[object Float32Array]"]=_e["[object Float64Array]"]=_e["[object Int8Array]"]=_e["[object Int16Array]"]=_e["[object Int32Array]"]=_e["[object Uint8Array]"]=_e["[object Uint8ClampedArray]"]=_e["[object Uint16Array]"]=_e["[object Uint32Array]"]=true,_e["[object Arguments]"]=_e["[object Array]"]=_e["[object ArrayBuffer]"]=_e["[object Boolean]"]=_e["[object DataView]"]=_e["[object Date]"]=_e["[object Error]"]=_e["[object Function]"]=_e["[object Map]"]=_e["[object Number]"]=_e["[object Object]"]=_e["[object RegExp]"]=_e["[object Set]"]=_e["[object String]"]=_e["[object WeakMap]"]=false;
var ge={};ge["[object Arguments]"]=ge["[object Array]"]=ge["[object ArrayBuffer]"]=ge["[object DataView]"]=ge["[object Boolean]"]=ge["[object Date]"]=ge["[object Float32Array]"]=ge["[object Float64Array]"]=ge["[object Int8Array]"]=ge["[object Int16Array]"]=ge["[object Int32Array]"]=ge["[object Map]"]=ge["[object Number]"]=ge["[object Object]"]=ge["[object RegExp]"]=ge["[object Set]"]=ge["[object String]"]=ge["[object Symbol]"]=ge["[object Uint8Array]"]=ge["[object Uint8ClampedArray]"]=ge["[object Uint16Array]"]=ge["[object Uint32Array]"]=true,
ge["[object Error]"]=ge["[object Function]"]=ge["[object WeakMap]"]=false;var ve,de=parseInt,Ae=typeof global=="object"&&global&&global.Object===Object&&global,we=typeof self=="object"&&self&&self.Object===Object&&self,me=Ae||we||Function("return this")(),Oe=typeof exports=="object"&&exports&&!exports.nodeType&&exports,Se=Oe&&typeof module=="object"&&module&&!module.nodeType&&module,ke=Se&&Se.exports===Oe,ze=ke&&Ae.process;t:{try{ve=ze&&ze.binding&&ze.binding("util");break t}catch(t){}ve=void 0}var xe=ve&&ve.isMap,Ee=ve&&ve.isSet,Ie=ve&&ve.isTypedArray,Fe=Array.prototype,Me=Object.prototype,$e=me["__core-js_shared__"],De=Function.prototype.toString,Ue=Me.hasOwnProperty,Be=function(){
var t=/[^.]+$/.exec($e&&$e.keys&&$e.keys.IE_PROTO||"");return t?"Symbol(src)_1."+t:""}(),Pe=Me.toString,Le=De.call(Object),Ne=RegExp("^"+De.call(Ue).replace(/[\\^$.*+?()[\]{}|]/g,"\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g,"$1.*?")+"$"),Ce=ke?me.Buffer:re,Te=me.Symbol,Ve=me.Uint8Array,Re=Ce?Ce.a:re,We=b(Object.getPrototypeOf),qe=Object.create,Ge=Me.propertyIsEnumerable,He=Fe.splice,Je=Te?Te.isConcatSpreadable:re,Ke=Te?Te.toStringTag:re,Qe=function(){try{var t=ht(Object,"defineProperty");
return t({},"",{}),t}catch(t){}}(),Xe=Object.getOwnPropertySymbols,Ye=Ce?Ce.isBuffer:re,Ze=b(Object.keys),tn=Math.max,en=Date.now,nn=ht(me,"DataView"),rn=ht(me,"Map"),on=ht(me,"Promise"),un=ht(me,"Set"),cn=ht(me,"WeakMap"),an=ht(Object,"create"),fn=St(nn),ln=St(rn),sn=St(on),bn=St(un),hn=St(cn),pn=Te?Te.prototype:re,yn=pn?pn.valueOf:re,jn=pn?pn.toString:re,_n=function(){function t(){}return function(e){return Bt(e)?qe?qe(e):(t.prototype=e,e=new t,t.prototype=re,e):{}}}();y.prototype.clear=function(){
this.__data__=an?an(null):{},this.size=0},y.prototype.delete=function(t){return t=this.has(t)&&delete this.__data__[t],this.size-=t?1:0,t},y.prototype.get=function(t){var e=this.__data__;return an?(t=e[t],"__lodash_hash_undefined__"===t?re:t):Ue.call(e,t)?e[t]:re},y.prototype.has=function(t){var e=this.__data__;return an?e[t]!==re:Ue.call(e,t)},y.prototype.set=function(t,e){var n=this.__data__;return this.size+=this.has(t)?0:1,n[t]=an&&e===re?"__lodash_hash_undefined__":e,this},j.prototype.clear=function(){
this.__data__=[],this.size=0},j.prototype.delete=function(t){var e=this.__data__;return t=m(e,t),!(0>t)&&(t==e.length-1?e.pop():He.call(e,t,1),--this.size,true)},j.prototype.get=function(t){var e=this.__data__;return t=m(e,t),0>t?re:e[t][1]},j.prototype.has=function(t){return-1<m(this.__data__,t)},j.prototype.set=function(t,e){var n=this.__data__,r=m(n,t);return 0>r?(++this.size,n.push([t,e])):n[r][1]=e,this},_.prototype.clear=function(){this.size=0,this.__data__={hash:new y,map:new(rn||j),string:new y
}},_.prototype.delete=function(t){return t=st(this,t).delete(t),this.size-=t?1:0,t},_.prototype.get=function(t){return st(this,t).get(t)},_.prototype.has=function(t){return st(this,t).has(t)},_.prototype.set=function(t,e){var n=st(this,t),r=n.size;return n.set(t,e),this.size+=n.size==r?0:1,this},g.prototype.add=g.prototype.push=function(t){return this.__data__.set(t,"__lodash_hash_undefined__"),this},g.prototype.has=function(t){return this.__data__.has(t)},v.prototype.clear=function(){this.__data__=new j,
this.size=0},v.prototype.delete=function(t){var e=this.__data__;return t=e.delete(t),this.size=e.size,t},v.prototype.get=function(t){return this.__data__.get(t)},v.prototype.has=function(t){return this.__data__.has(t)},v.prototype.set=function(t,e){var n=this.__data__;if(n instanceof j){var r=n.__data__;if(!rn||199>r.length)return r.push([t,e]),this.size=++n.size,this;n=this.__data__=new _(r)}return n.set(t,e),this.size=n.size,this};var gn=function(t,e){return function(n,r){if(null==n)return n;if(!Mt(n))return t(n,r);
for(var o=n.length,u=e?o:-1,c=Object(n);(e?u--:++u<o)&&false!==r(c[u],u,c););return n}}(function(t,e){return t&&vn(t,e,Jt)}),vn=function(t){return function(e,n,r){var o=-1,u=Object(e);r=r(e);for(var c=r.length;c--;){var i=r[t?c:++o];if(false===n(u[i],i,u))break}return e}}(),dn=Qe?function(t,e){return Qe(t,"toString",{configurable:true,enumerable:false,value:Xt(e),writable:true})}:Yt,An=Xe?function(t){return null==t?[]:(t=Object(t),n(Xe(t),function(e){return Ge.call(t,e)}))}:ee,wn=Xe?function(t){for(var e=[];t;)o(e,An(t)),
t=We(t);return e}:ee,mn=M;(nn&&"[object DataView]"!=mn(new nn(new ArrayBuffer(1)))||rn&&"[object Map]"!=mn(new rn)||on&&"[object Promise]"!=mn(on.resolve())||un&&"[object Set]"!=mn(new un)||cn&&"[object WeakMap]"!=mn(new cn))&&(mn=function(t){var e=M(t);if(t=(t="[object Object]"==e?t.constructor:re)?St(t):"")switch(t){case fn:return"[object DataView]";case ln:return"[object Map]";case sn:return"[object Promise]";case bn:return"[object Set]";case hn:return"[object WeakMap]"}return e});var On=function(t){
var e=0,n=0;return function(){var r=en(),o=16-(r-n);if(n=r,0<o){if(800<=++e)return arguments[0]}else e=0;return t.apply(re,arguments)}}(dn),Sn=function(t){t=Et(t,function(t){return 500===e.size&&e.clear(),t});var e=t.cache;return t}(function(t){var e=[];return 46===t.charCodeAt(0)&&e.push(""),t.replace(ae,function(t,n,r,o){e.push(r?o.replace(le,"$1"):n||t)}),e}),kn=function(t){return function(e,n,r){var o=Object(e);if(!Mt(e)){var u=lt(n,3);e=Jt(e),n=function(t){return u(o[t],t,o)}}return n=t(e,n,r),
-1<n?o[u?e[n]:n]:re}}(kt);Et.Cache=_;var zn=U(function(){return arguments}())?U:function(t){return Pt(t)&&Ue.call(t,"callee")&&!Ge.call(t,"callee")},xn=Array.isArray,En=Ye||ne,In=xe?f(xe):P,Fn=Ee?f(Ee):N,Mn=Ie?f(Ie):C,$n=ot(function(t,e,n){q(t,e,n)}),Dn=ot(function(t,e,n,r){q(t,e,n,r)}),Un=function(t){return On(mt(t,re,zt),t+"")}(function(t,e){var n={};if(null==t)return n;var o=false;e=r(e,function(e){return e=Q(e,t),o||(o=1<e.length),e}),et(t,ft(t),n),o&&(n=z(n,7,ut));for(var u=e.length;u--;)K(n,e[u]);
return n});p.constant=Xt,p.flatten=zt,p.iteratee=Zt,p.keys=Jt,p.keysIn=Kt,p.memoize=Et,p.merge=$n,p.mergeWith=Dn,p.negate=It,p.omit=Un,p.property=te,p.reject=function(t,e){return(xn(t)?n:x)(t,It(lt(e,3)))},p.toPlainObject=Wt,p.values=Qt,p.cloneDeep=function(t){return z(t,5)},p.cloneDeepWith=function(t,e){return e=typeof e=="function"?e:re,z(t,5,e)},p.eq=Ft,p.find=kn,p.findIndex=kt,p.get=Gt,p.has=function(t,e){return null!=t&&pt(t,e,$)},p.hasIn=Ht,p.identity=Yt,p.includes=function(t,e,n,r){if(t=Mt(t)?t:Qt(t),
n=n&&!r?Vt(n):0,r=t.length,0>n&&(n=tn(r+n,0)),Nt(t))t=n<=r&&-1<t.indexOf(e,n);else{if(r=!!r){if(e===e)t:{for(n-=1,r=t.length;++n<r;)if(t[n]===e){t=n;break t}t=-1}else t=c(t,i,n);r=-1<t}t=r}return t},p.isArguments=zn,p.isArray=xn,p.isArrayLike=Mt,p.isArrayLikeObject=$t,p.isBuffer=En,p.isEmpty=function(t){if(null==t)return true;if(Mt(t)&&(xn(t)||typeof t=="string"||typeof t.splice=="function"||En(t)||Mn(t)||zn(t)))return!t.length;var e=mn(t);if("[object Map]"==e||"[object Set]"==e)return!t.size;if(At(t))return!V(t).length;
for(var n in t)if(Ue.call(t,n))return false;return true},p.isEqual=function(t,e){return B(t,e)},p.isFunction=Dt,p.isLength=Ut,p.isMap=In,p.isNull=function(t){return null===t},p.isObject=Bt,p.isObjectLike=Pt,p.isPlainObject=Lt,p.isSet=Fn,p.isString=Nt,p.isSymbol=Ct,p.isTypedArray=Mn,p.last=xt,p.stubArray=ee,p.stubFalse=ne,p.toFinite=Tt,p.toInteger=Vt,p.toNumber=Rt,p.toString=qt,p.VERSION="4.17.5",Se&&((Se.exports=p)._=p,Oe._=p)}).call(this);
}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],2:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/
const CONSTANTS = {
  /**
   * @typedef {String} ItemType
   **/

  /**
   * Enumeration of data layer item types.
   *
   * @enum {ItemType}
   * @readonly
   */
  itemType: {
    /** Represents an item of type data */
    DATA: 'data',
    /** Represents an item of type function */
    FCTN: 'fctn',
    /** Represents an item of type event */
    EVENT: 'event',
    /** Represents an item of type listener on */
    LISTENER_ON: 'listenerOn',
    /** Represents an item of type listener off */
    LISTENER_OFF: 'listenerOff'
  },

  /**
   * @typedef {String} DataLayerEvent
   **/

  /**
   * Enumeration of data layer events.
   *
   * @enum {DataLayerEvent}
   * @readonly
   */
  dataLayerEvent: {
    /** Represents an event triggered for any change in the data layer state */
    CHANGE: 'adobeDataLayer:change',
    /** Represents an event triggered for any event push to the data layer */
    EVENT: 'adobeDataLayer:event'
  },

  /**
   * @typedef {String} ListenerScope
   **/

  /**
   * Enumeration of listener scopes.
   *
   * @enum {ListenerScope}
   * @readonly
   */
  listenerScope: {
    /** Past events only */
    PAST: 'past',
    /** Future events only */
    FUTURE: 'future',
    /** All events, past and future */
    ALL: 'all'
  }
};

module.exports = CONSTANTS;

},{}],3:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../custom-lodash');
const version = acdlreq('../version.json').version;
const cloneDeep = _.cloneDeep;
const get = _.get;

const Item = acdlreq('./item');
const Listener = acdlreq('./listener');
const ListenerManager = acdlreq('./listenerManager');
const CONSTANTS = acdlreq('./constants');
const customMerge = acdlreq('./utils/customMerge');

/**
 * Manager
 *
 * @class Manager
 * @classdesc Data Layer manager that augments the passed data layer array and handles eventing.
 * @param {Object} config The Data Layer manager configuration.
 */
module.exports = function(config) {
  const _config = config || {};
  let _dataLayer = [];
  let _preLoadedItems = [];
  let _state = {};
  let _listenerManager;

  const DataLayerManager = {
    getState: function() {
      return _state;
    },
    getDataLayer: function() {
      return _dataLayer;
    }
  };

  _initialize();
  _augment();
  _processItems();

  /**
   * Initializes the data layer.
   *
   * @private
   */
  function _initialize() {
    if (!Array.isArray(_config.dataLayer)) {
      _config.dataLayer = [];
    }

    // Remove preloaded items from the data layer array and add those to the array of preloaded items
    _preLoadedItems = _config.dataLayer.splice(0, _config.dataLayer.length);
    _dataLayer = _config.dataLayer;
    _dataLayer.version = version;
    _state = {};
    _listenerManager = ListenerManager(DataLayerManager);
  };

  /**
   * Updates the state with the item.
   *
   * @param {Item} item The item.
   * @private
   */
  function _updateState(item) {
    _state = customMerge(_state, item.data);
  };

  /**
   * Augments the data layer Array Object, overriding: push() and adding getState(), addEventListener and removeEventListener.
   *
   * @private
   */
  function _augment() {
    /**
     * Pushes one or more items to the data layer.
     *
     * @param {...ItemConfig} var_args The items to add to the data layer.
     * @returns {Number} The length of the data layer following push.
     */
    _dataLayer.push = function(var_args) { /* eslint-disable-line camelcase */
      const pushArguments = arguments;
      const filteredArguments = arguments;

      Object.keys(pushArguments).forEach(function(key) {
        const itemConfig = pushArguments[key];
        const item = Item(itemConfig);

        if (!item.valid) {
          _logInvalidItemError(item);
          delete filteredArguments[key];
        }
        switch (item.type) {
          case CONSTANTS.itemType.DATA:
          case CONSTANTS.itemType.EVENT: {
            _processItem(item);
            break;
          }
          case CONSTANTS.itemType.FCTN: {
            delete filteredArguments[key];
            _processItem(item);
            break;
          }
          case CONSTANTS.itemType.LISTENER_ON:
          case CONSTANTS.itemType.LISTENER_OFF: {
            delete filteredArguments[key];
          }
        }
      });

      if (filteredArguments[0]) {
        return Array.prototype.push.apply(this, filteredArguments);
      }
    };

    /**
     * Returns a deep copy of the data layer state or of the object defined by the path.
     *
     * @param {Array|String} path The path of the property to get.
     * @returns {*} Returns a deep copy of the resolved value if a path is passed, a deep copy of the data layer state otherwise.
     */
    _dataLayer.getState = function(path) {
      if (path) {
        return get(cloneDeep(_state), path);
      }
      return cloneDeep(_state);
    };

    /**
     * Event listener callback.
     *
     * @callback eventListenerCallback A function that is called when the event of the specified type occurs.
     * @this {DataLayer}
     * @param {Object} event The event object pushed to the data layer that triggered the callback.
     */

    /**
     * Sets up a function that will be called whenever the specified event is triggered.
     *
     * @param {String} type A case-sensitive string representing the event type to listen for.
     * @param {eventListenerCallback} callback A function that is called when the event of the specified type occurs.
     * @param {Object} [options] Optional characteristics of the event listener.
     * @param {String} [options.path] The path in the state object to filter the listening to.
     * @param {('past'|'future'|'all')} [options.scope] The timing to filter the listening to:
     *      - {String} past The listener is triggered for past events only.
     *      - {String} future The listener is triggered for future events only.
     *      - {String} all The listener is triggered for both past and future events (default value).
     */
    _dataLayer.addEventListener = function(type, callback, options) {
      const eventListenerItem = Item({
        on: type,
        handler: callback,
        scope: options && options.scope,
        path: options && options.path
      });

      _processItem(eventListenerItem);
    };

    /**
     * Removes an event listener previously registered with addEventListener().
     *
     * @param {String} type A case-sensitive string representing the event type to listen for.
     * @param {Function} [listener] Optional function that is to be removed.
     */
    _dataLayer.removeEventListener = function(type, listener) {
      const eventListenerItem = Item({
        off: type,
        handler: listener
      });

      _processItem(eventListenerItem);
    };
  };

  /**
   * Processes all items that already exist on the stack.
   *
   * @private
   */
  function _processItems() {
    for (let i = 0; i < _preLoadedItems.length; i++) {
      _dataLayer.push(_preLoadedItems[i]);
    }
  };

  /**
   * Processes an item pushed to the stack.
   *
   * @param {Item} item The item to process.
   * @private
   */
  function _processItem(item) {
    if (!item.valid) {
      _logInvalidItemError(item);
      return;
    }

    /**
     * Returns all items before the provided one.
     *
     * @param {Item} item The item.
     * @returns {Array<Item>} The items before.
     * @private
     */
    function _getBefore(item) {
      if (!(_dataLayer.length === 0 || item.index > _dataLayer.length - 1)) {
        return _dataLayer.slice(0, item.index).map(itemConfig => Item(itemConfig));
      }
      return [];
    }

    const typeProcessors = {
      data: function(item) {
        _updateState(item);
        _listenerManager.triggerListeners(item);
      },
      fctn: function(item) {
        item.config.call(_dataLayer, _dataLayer);
      },
      event: function(item) {
        if (item.data) {
          _updateState(item);
        }
        _listenerManager.triggerListeners(item);
      },
      listenerOn: function(item) {
        const listener = Listener(item);
        switch (listener.scope) {
          case CONSTANTS.listenerScope.PAST: {
            for (const registeredItem of _getBefore(item)) {
              _listenerManager.triggerListener(listener, registeredItem);
            }
            break;
          }
          case CONSTANTS.listenerScope.FUTURE: {
            _listenerManager.register(listener);
            break;
          }
          case CONSTANTS.listenerScope.ALL: {
            const registered = _listenerManager.register(listener);
            if (registered) {
              for (const registeredItem of _getBefore(item)) {
                _listenerManager.triggerListener(listener, registeredItem);
              }
            }
          }
        }
      },
      listenerOff: function(item) {
        _listenerManager.unregister(Listener(item));
      }
    };

    typeProcessors[item.type](item);
  };

  /**
   * Logs error for invalid item pushed to the data layer.
   *
   * @param {Item} item The invalid item.
   * @private
   */
  function _logInvalidItemError(item) {
    const message = 'The following item cannot be handled by the data layer ' +
      'because it does not have a valid format: ' +
      JSON.stringify(item.config);
    console.error(message);
  };

  return DataLayerManager;
};

},{"../custom-lodash":1,"../version.json":14,"./constants":2,"./item":5,"./listener":7,"./listenerManager":8,"./utils/customMerge":10}],4:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const DataLayerManager = acdlreq('./dataLayerManager');

/**
 * Data Layer.
 *
 * @type {Object}
 */
const DataLayer = {
  Manager: DataLayerManager
};

window.adobeDataLayer = window.adobeDataLayer || [];

DataLayer.Manager({
  dataLayer: window.adobeDataLayer
});

/**
 * @typedef  {Object} ListenerOnConfig
 * @property {String} on Name of the event to bind to.
 * @property {String} [path] Object key in the state to bind to.
 * @property {ListenerScope} [scope] Scope of the listener.
 * @property {Function} handler Handler to execute when the bound event is triggered.
 */

/**
 * @typedef  {Object} ListenerOffConfig
 * @property {String} off Name of the event to unbind.
 * @property {String} [path] Object key in the state to bind to.
 * @property {ListenerScope} [scope] Scope of the listener.
 * @property {Function} [handler] Handler for a previously attached event to unbind.
 */

/**
 * @typedef {Object} DataConfig
 * @property {Object} data Data to be updated in the state.
 */

/**
 * @typedef {Object} EventConfig
 * @property {String} event Name of the event.
 * @property {Object} [eventInfo] Additional information to pass to the event handler.
 * @property {DataConfig.data} [data] Data to be updated in the state.
 */

/**
 * @typedef {DataConfig | EventConfig | ListenerOnConfig | ListenerOffConfig} ItemConfig
 */

/**
 * Triggered when there is change in the data layer state.
 *
 * @event DataLayerEvent.CHANGE
 * @type {Object}
 * @property {Object} data Data pushed that caused a change in the data layer state.
 */

/**
 * Triggered when an event is pushed to the data layer.
 *
 * @event DataLayerEvent.EVENT
 * @type {Object}
 * @property {String} name Name of the committed event.
 * @property {Object} eventInfo Additional information passed with the committed event.
 * @property {Object} data Data that was pushed alongside the event.
 */

/**
 * Triggered when an arbitrary event is pushed to the data layer.
 *
 * @event <custom>
 * @type {Object}
 * @property {String} name Name of the committed event.
 * @property {Object} eventInfo Additional information passed with the committed event.
 * @property {Object} data Data that was pushed alongside the event.
 */

module.exports = DataLayer;

},{"./dataLayerManager":3}],5:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../custom-lodash');
const isPlainObject = _.isPlainObject;
const isEmpty = _.isEmpty;
const omit = _.omit;
const find = _.find;

const dataMatchesContraints = acdlreq('./utils/dataMatchesContraints');
const ITEM_CONSTRAINTS = acdlreq('./itemConstraints');
const CONSTANTS = acdlreq('./constants');

/**
 * Constructs a data layer item.
 *
 * @param {ItemConfig} itemConfig The data layer item configuration.
 * @param {Number} index The item index in the array of existing items.
 */

module.exports = function(itemConfig, index) {
  const _config = itemConfig;
  const _index = index;
  const _type = getType();
  const _data = getData();
  const _valid = !!_type;

  function getType() {
    return find(Object.keys(ITEM_CONSTRAINTS), key => dataMatchesContraints(_config, ITEM_CONSTRAINTS[key])) ||
      (typeof _config === 'function' && CONSTANTS.itemType.FCTN) ||
      (isPlainObject(_config) && CONSTANTS.itemType.DATA);
  }

  function getData() {
    const data = omit(_config, Object.keys(ITEM_CONSTRAINTS.event));
    if (!isEmpty(data)) {
      return data;
    }
  }

  return {
    /**
     * Returns the item configuration.
     *
     * @returns {ItemConfig} The item configuration.
     */
    config: _config,

    /**
     * Returns the item type.
     *
     * @returns {itemType} The item type.
     */
    type: _type,

    /**
     * Returns the item data.
     *
     * @returns {DataConfig} The item data.
     */
    data: _data,

    /**
     * Indicates whether the item is valid.
     *
     * @returns {Boolean} true if the item is valid, false otherwise.
     */
    valid: _valid,

    /**
     * Returns the index of the item in the array of existing items (added with the standard Array.prototype.push())
     *
     * @returns {Number} The index of the item in the array of existing items if it exists, -1 otherwise.
     */
    index: _index
  };
};

},{"../custom-lodash":1,"./constants":2,"./itemConstraints":6,"./utils/dataMatchesContraints":11}],6:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

/**
 * Constraints for each type of the item configuration.
 */

const itemConstraints = {
  event: {
    event: {
      type: 'string'
    },
    eventInfo: {
      optional: true
    }
  },
  listenerOn: {
    on: {
      type: 'string'
    },
    handler: {
      type: 'function'
    },
    scope: {
      type: 'string',
      values: ['past', 'future', 'all'],
      optional: true
    },
    path: {
      type: 'string',
      optional: true
    }
  },
  listenerOff: {
    off: {
      type: 'string'
    },
    handler: {
      type: 'function',
      optional: true
    },
    scope: {
      type: 'string',
      values: ['past', 'future', 'all'],
      optional: true
    },
    path: {
      type: 'string',
      optional: true
    }
  }
};

module.exports = itemConstraints;

},{}],7:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const CONSTANTS = acdlreq('./constants');

/**
 * Constructs a data layer listener.
 *
 * @param {Item} item The item from which to construct the listener.
 */

module.exports = function(item) {
  const _event = item.config.on || item.config.off;
  const _handler = item.config.handler || null;
  const _scope = item.config.scope || (item.config.on && CONSTANTS.listenerScope.ALL) || null;
  const _path = item.config.path || null;

  return {
    /**
     * Returns the listener event name.
     *
     * @returns {String} The listener event name.
     */
    event: _event,

    /**
     * Returns the listener handler.
     *
     * @returns {(Function|null)} The listener handler.
     */
    handler: _handler,

    /**
     * Returns the listener scope.
     *
     * @returns {(String|null)} The listener scope.
     */
    scope: _scope,

    /**
     * Returns the listener path.
     *
     * @returns {(String|null)} The listener path.
     */
    path: _path
  };
};

},{"./constants":2}],8:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../custom-lodash');
const cloneDeep = _.cloneDeep;

const constants = acdlreq('./constants');
const listenerMatch = acdlreq('./utils/listenerMatch');
const indexOfListener = acdlreq('./utils/indexOfListener');

/**
 * Creates a listener manager.
 *
 * @param {Manager} dataLayerManager The data layer manager.
 * @returns {ListenerManager} A listener manager.
 */
module.exports = function(dataLayerManager) {
  const _listeners = {};
  const _dataLayerManager = dataLayerManager;

  /**
   * Find index of listener in listeners object.
   */
  const _indexOfListener = indexOfListener.bind(null, _listeners);

  const ListenerManager = {
    /**
     * Registers a listener.
     *
     * @function
     * @param {Listener} listener The listener to register.
     * @returns {Boolean} true if the listener was registered, false otherwise.
     */
    register: function(listener) {
      const event = listener.event;

      if (Object.prototype.hasOwnProperty.call(_listeners, event)) {
        if (_indexOfListener(listener) === -1) {
          _listeners[listener.event].push(listener);
          return true;
        }
      } else {
        _listeners[listener.event] = [listener];
        return true;
      }
      return false;
    },
    /**
     * Unregisters a listener.
     *
     * @function
     * @param {Listener} listener The listener to unregister.
     */
    unregister: function(listener) {
      const event = listener.event;

      if (Object.prototype.hasOwnProperty.call(_listeners, event)) {
        if (!(listener.handler || listener.scope || listener.path)) {
          _listeners[event] = [];
        } else {
          const index = _indexOfListener(listener);
          if (index > -1) {
            _listeners[event].splice(index, 1);
          }
        }
      }
    },
    /**
     * Triggers listeners related to the passed item.
     *
     * @function
     * @param {Item} item Item to trigger listener for.
     */
    triggerListeners: function(item) {
      const triggeredEvents = _getTriggeredEvents(item);
      triggeredEvents.forEach(function(event) {
        if (Object.prototype.hasOwnProperty.call(_listeners, event)) {
          for (const listener of _listeners[event]) {
            _callHandler(listener, item);
          }
        }
      });
    },
    /**
     * Triggers a single listener on the passed item.
     *
     * @function
     * @param {Listener} listener Listener to call.
     * @param {Item} item Item to call the listener with.
     */
    triggerListener: function(listener, item) {
      _callHandler(listener, item);
    }
  };

  /**
   * Calls the listener handler on the item if a match is found.
   *
   * @param {Listener} listener The listener.
   * @param {Item} item The item.
   * @private
   */
  function _callHandler(listener, item) {
    if (listenerMatch(listener, item)) {
      const callbackArgs = [cloneDeep(item.config)];
      listener.handler.apply(_dataLayerManager.getDataLayer(), callbackArgs);
    }
  }

  /**
   * Returns the names of the events that are triggered for this item.
   *
   * @param {Item} item The item.
   * @returns {Array<String>} The names of the events that are triggered for this item.
   * @private
   */
  function _getTriggeredEvents(item) {
    const triggeredEvents = [];

    switch (item.type) {
      case constants.itemType.DATA: {
        triggeredEvents.push(constants.dataLayerEvent.CHANGE);
        break;
      }
      case constants.itemType.EVENT: {
        triggeredEvents.push(constants.dataLayerEvent.EVENT);
        if (item.data) triggeredEvents.push(constants.dataLayerEvent.CHANGE);
        if (item.config.event !== constants.dataLayerEvent.CHANGE) {
          triggeredEvents.push(item.config.event);
        }
        break;
      }
    }
    return triggeredEvents;
  }

  return ListenerManager;
};

},{"../custom-lodash":1,"./constants":2,"./utils/indexOfListener":12,"./utils/listenerMatch":13}],9:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../../custom-lodash');
const has = _.has;
const get = _.get;

/**
  * Checks if the object contains an ancestor that is set to null or undefined.
  *
  * @param {Object} object The object to check.
  * @param {String} path The path to check.
  * @returns {Boolean} true if the object contains an ancestor that is set to null or undefined, false otherwise.
  * @private
  */
module.exports = function(object, path) {
  let ancestorPath = path.substring(0, path.lastIndexOf('.'));
  while (ancestorPath) {
    if (has(object, ancestorPath)) {
      const ancestorValue = get(object, ancestorPath);
      if (ancestorValue === null || ancestorValue === undefined) {
        return true;
      }
    }
    ancestorPath = ancestorPath.substring(0, ancestorPath.lastIndexOf('.'));
  }

  return false;
};

},{"../../custom-lodash":1}],10:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../../custom-lodash');
const cloneDeepWith = _.cloneDeepWith;
const isObject = _.isObject;
const isArray = _.isArray;
const reject = _.reject;
const mergeWith = _.mergeWith;
const isNull = _.isNull;

/**
 * Merges the source into the object and sets objects as 'undefined' if they are 'undefined' in the source object.
 *
 * @param {Object} object The object into which to merge.
 * @param {Object} source The source to merge with.
 * @returns {Object} The object into which source was merged in.
 */
module.exports = function(object, source) {
  const makeOmittingCloneDeepCustomizer = function(predicate) {
    return function omittingCloneDeepCustomizer(value, key, object, stack) {
      if (isObject(value)) {
        if (isArray(value)) {
          return reject(value, predicate).map(item => cloneDeepWith(item, omittingCloneDeepCustomizer));
        }

        const clone = {};
        for (const subKey of Object.keys(value)) {
          if (!predicate(value[subKey])) {
            clone[subKey] = cloneDeepWith(value[subKey], omittingCloneDeepCustomizer);
          }
        }
        return clone;
      }
      return undefined;
    };
  };

  const customizer = function(objValue, srcValue, key, object) {
    if (typeof srcValue === 'undefined' || srcValue === null) {
      return null;
    }
  };

  const omitDeep = function(value, predicate = (val) => !val) {
    return cloneDeepWith(value, makeOmittingCloneDeepCustomizer(predicate));
  };

  mergeWith(object, source, customizer);

  // Remove null or undefined objects
  object = omitDeep(object, isNull);

  return object;
};

},{"../../custom-lodash":1}],11:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../../custom-lodash');
const find = _.find;
const includes = _.includes;

module.exports = function(data, constraints) {
  // Go through all constraints and find one which does not match the data
  const foundObjection = find(Object.keys(constraints), key => {
    const type = constraints[key].type;
    const supportedValues = key && constraints[key].values;
    const mandatory = !constraints[key].optional;
    const value = data[key];
    const valueType = typeof value;
    const incorrectType = type && valueType !== type;
    const noMatchForSupportedValues = supportedValues && !includes(supportedValues, value);
    if (mandatory) {
      return !value || incorrectType || noMatchForSupportedValues;
    } else {
      return value && (incorrectType || noMatchForSupportedValues);
    }
  });
  // If no objections found then data matches contraints
  return typeof foundObjection === 'undefined';
};

},{"../../custom-lodash":1}],12:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../../custom-lodash');
const isEqual = _.isEqual;

module.exports = function(listeners, listener) {
  const event = listener.event;

  if (Object.prototype.hasOwnProperty.call(listeners, event)) {
    for (const [index, registeredListener] of listeners[event].entries()) {
      if (isEqual(registeredListener.handler, listener.handler)) {
        return index;
      }
    }
  }
  return -1;
};

},{"../../custom-lodash":1}],13:[function(acdlreq,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = acdlreq('../../custom-lodash');
const has = _.has;

const CONSTANTS = acdlreq('../constants');
const ancestorRemoved = acdlreq('./ancestorRemoved');

/**
 * Checks if the listener matches the item.
 *
 * @param {Listener} listener The listener.
 * @param {Item} item The item.
 * @returns {Boolean} true if listener matches the item, false otherwise.
 */
function listenerMatch(listener, item) {
  const event = listener.event;
  const itemConfig = item.config;
  let matches = false;

  if (item.type === CONSTANTS.itemType.DATA) {
    if (event === CONSTANTS.dataLayerEvent.CHANGE) {
      matches = selectorMatches(listener, item);
    }
  } else if (item.type === CONSTANTS.itemType.EVENT) {
    if (event === CONSTANTS.dataLayerEvent.EVENT || event === itemConfig.event) {
      matches = selectorMatches(listener, item);
    }
    if (item.data && event === CONSTANTS.dataLayerEvent.CHANGE) {
      matches = selectorMatches(listener, item);
    }
  }

  return matches;
}

/**
 * Checks if a listener has a selector that points to an object in the data payload of an item.
 *
 * @param {Listener} listener The listener to extract the selector from.
 * @param {Item} item The item.
 * @returns {Boolean} true if a selector is not provided or if the given selector is matching, false otherwise.
 * @private
 */
function selectorMatches(listener, item) {
  if (item.data && listener.path) {
    return has(item.data, listener.path) || ancestorRemoved(item.data, listener.path);
  }

  return true;
}

module.exports = listenerMatch;

},{"../../custom-lodash":1,"../constants":2,"./ancestorRemoved":9}],14:[function(acdlreq,module,exports){
module.exports={
  "version": "2.0.0"
}

},{}]},{},[4])

